export default {
  "APP_DRAFT_VIEW": "COMMON_ADMIN",
  "APP_ASSIGNED_VIEW": "COMMON_ADMIN",
  "APP_PENDING_REVIEW_VIEW": "COMMON_ADMIN",
  "APP_CLOSED_VIEW": "COMMON_ADMIN",
  "APP_STORE_PERMISSIONS_VIEW": "COMMON_ADMIN",
  "APP_SETTINGS_VIEW": "",
  "APP_COUNT_VIEW": "FULFILL_INVCUNT_ADMIN OR INV_COUNT_ADMIN",
  "APP_PRODUCT_IDENTIFIER_UPDATE": "COMMON_ADMIN",
  "INVCOUNT_APP_VIEW": "INVCOUNT_APP_VIEW"
} as any